import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../modules/view/QueryRequestProvider'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import SelectAsync from 'react-select/async'
import debounce from 'debounce-promise'
import {getCompanies} from '../../core/_requests'
import Select from 'react-select'
import StyledDatePicker from '../../../../modules/view/StyledDatePicker'
import {format} from 'date-fns'
import styled from 'styled-components'
import {CARD_STATUS, CARD_TYPES} from '../../core/_models'
import {useMemo, useRef} from 'react'
import SelectInputCustomStyle from '../../../../modules/view/styles/SelectInputCustomStyle'
import {getPossibleResponsiblesFilter} from '../../../funnel/core/_requests'
import {Dropdown} from 'react-bootstrap'
import {components, Props as SelectProps} from 'react-select'
import StyledDatetimePicker from '../../../../modules/view/StyledDatetimePicker'
import FieldTypeInput from '../../../settings/custom-forms/manage-modal/CustomFormsFormComponents/FieldTypeInput'

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none;
  }
  position: relative;
  top: 2px;
  left: 22px;
`
const ClearIndicator = (props: any) => {
  const {children = <components.ClearIndicator {...props} />, clearValue, innerProps} = props

  return (
    <div
      {...innerProps}
      onMouseDown={(e) => {
        e.stopPropagation() // Impede que o evento clique se propague
        clearValue() // Limpa o valor
      }}
    >
      {children}
    </div>
  )
}

const CardsListFilter = ({cardType}: {cardType: number}) => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [company, setCompany] = useState<any | undefined>()
  const [responsible, setResponsible] = useState<any | undefined>()
  const [status, setStatus] = useState<any | undefined>()
  const [createdStart, setCreatedStart] = useState<any | undefined>(null)
  const [createdEnd, setCreatedEnd] = useState<any | undefined>(null)
  const [closeDateStartAt, setCloseDateStartAt] = useState<any | undefined>()
  const [closeDateEndAt, setCloseDateEndAt] = useState<any | undefined>()

  const [closeDateAtIsOpen, setCloseDateAtIsOpen] = useState({
    dateStart: false,
    dateEnd: false,
  })

  const [createdAtIsOpen, setCreatedAtIsOpen] = useState({
    createdStart: false,
    createdEnd: false,
  })

  const closeDateStartRef: any = useRef(null)
  const closeDateEndRef: any = useRef(null)
  const createdStartRef: any = useRef(null)
  const createdEndRef: any = useRef(null)
  const dropdownRef: any = useRef(null)
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setStatus(null)
    setResponsible(null)
    setCompany(null)
    setCreatedStart(null)
    setCreatedEnd(null)
    setCloseDateStartAt(null)
    setCloseDateEndAt(null)
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
   
    updateState({
      filter: {
        status: status?.value,
        responsibleId: responsible?.value,
        companyId: company?.value,
        createdAtStart: createdStart ? format(createdStart, 'yyyy-MM-dd 00:00:00') : undefined,
        createdAtEnd: createdEnd ? format(createdEnd, 'yyyy-MM-dd 23:59:59') : undefined,
        closeDateAtStart: closeDateStartAt
          ? format(closeDateStartAt, 'yyyy-MM-dd HH:mm:ss')
          : undefined,
        closeDateAtEnd: closeDateEndAt ? format(closeDateEndAt, 'yyyy-MM-dd HH:mm:ss') : undefined,
      },
      ...initialQueryState,
    })

    setShowDropdown(false)
  }

  const statusOptions = useMemo(() => {
    let options: any[] = []

    options.push({value: '', label: 'Todos'})

    if (!cardType) return options

    Object.values(CARD_STATUS).forEach((status: any) => {
      if (status.cardType.includes(cardType)) {
        options.push({
          value: status.id,
          label: status.label,
        })
      }
    })

    return options
  }, [])

  const companiesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let companies = await getCompanies(
        'page=1&perPage=20&search=' + encodeURIComponent(inputValue)
      )
      if (companies && companies.data != undefined) {
        for (let i = 0; i < companies?.data?.length; i++) {
          list.push({label: companies.data[i].name, value: companies.data[i].id})
        }
      }
      resolve(list)
    })

  const debounceCompaniesOptions = debounce(companiesOptions, 500)

  const responsiblesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps: any = await getPossibleResponsiblesFilter(
        'search=' + encodeURIComponent(inputValue) + '&only_active=true'
      )
      if (resps) {
        for (let i = 0; i < resps?.length; i++) {
          list.push({label: resps[i]?.name, value: resps[i]?.id})
        }
      }
      return resolve(list)
    })
  const debounceResponsiblesOptions = debounce(responsiblesOptions, 500)

  useEffect(() => {
    let timeoutId: any = null
    function handleClickOutside(event: any) {
      if (
        dropdownRef?.current &&
        !dropdownRef.current?.contains(event?.target) &&
        !createdStartRef.current?.contains(event?.target) &&
        !createdEndRef.current?.contains(event?.target)
      ) {
        //if para ve se onBlur foi fora do dropdown e se todos os calendarios estiverem fechados
        if (!Object.values(createdAtIsOpen).some((status) => status)) {
          timeoutId = setTimeout(() => {
            //delay pra nao dar bug visual
            setShowDropdown(false)
          }, 150)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [createdAtIsOpen, closeDateAtIsOpen])

  return (
    <div className='m-0' id='cardFilterDropdown'>
      <Dropdown ref={dropdownRef} id='dropdown-true' show={showDropdown}>
        <StyledDropdownToggle
          variant='wl-custom-primary-collor-inverse'
          id='dropdown-basic'
          className='btn btn-sm me-3'
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <i className='fa-solid fa-filter fs-2'></i>
          Filtros
        </StyledDropdownToggle>

        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown w-350px w-md-425px'>
          <Dropdown.Header className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
          </Dropdown.Header>
          <div className='separator border-gray-200'></div>
          <div className='px-7 py-5' data-kt-user-table-filter='form'>
            <div className='mb-7'>
              <InputFieldSet label={'Status'}>
                <Select
                  placeholder={'Selecione'}
                  name='status'
                  options={statusOptions}
                  onChange={(selectedOption) => setStatus(selectedOption)}
                  className='fieldset-input'
                  value={status}
                  styles={SelectInputCustomStyle}
                />
              </InputFieldSet>
            </div>
            <div className='mb-7'>
              <InputFieldSet label={'Responsável'}>
                <SelectAsync
                  placeholder={''}
                  isClearable
                  name='responsible'
                  loadOptions={debounceResponsiblesOptions}
                  value={responsible}
                  onChange={(selectedOption) => setResponsible(selectedOption)}
                  noOptionsMessage={() => 'Sem registros...Digite para buscar'}
                  className='fieldset-input'
                  styles={SelectInputCustomStyle}
                  components={{ClearIndicator}} //corrigir bug de fechar dropdown apos clicar no X do select
                />
              </InputFieldSet>
            </div>
            <div className='mb-7'>
              <InputFieldSet label={'Empresa'}>
                <SelectAsync
                  placeholder={''}
                  isClearable
                  name='company'
                  loadOptions={debounceCompaniesOptions}
                  value={company}
                  onChange={(selectedOption) => setCompany(selectedOption)}
                  noOptionsMessage={() => 'Sem registros...Digite para buscar'}
                  className='fieldset-input'
                  styles={SelectInputCustomStyle}
                  components={{ClearIndicator}} //corrigir bug de fechar dropdown apos clicar no X do select
                />
              </InputFieldSet>
            </div>

            <div className='d-flex justify-content-between mb-7'>
              <InputFieldSet label={'Data de Criação Início'} style={{marginRight: 5}}>
                <StyledDatePicker
                  ref={createdStartRef}
                  value={createdStart}
                  onChange={(e: any) => {
                    setCreatedStart(e)
                    setCreatedAtIsOpen((prevState) => ({...prevState, createdStart: false}))
                  }}
                  open={createdAtIsOpen.createdStart}
                  onClose={() =>
                    setCreatedAtIsOpen((prevState) => ({...prevState, createdStart: false}))
                  }
                  onOpen={() =>
                    setCreatedAtIsOpen((prevState) => ({...prevState, createdStart: true}))
                  }
                />
              </InputFieldSet>

              <InputFieldSet label={'Data de Criação Fim'}>
                <StyledDatePicker
                  ref={createdEndRef}
                  value={createdEnd}
                  onChange={(e: any) => {
                    setCreatedEnd(e)
                    setCreatedAtIsOpen((prevState) => ({...prevState, createdEnd: false}))
                  }}
                  open={createdAtIsOpen.createdEnd}
                  onClose={() =>
                    setCreatedAtIsOpen((prevState) => ({...prevState, createdEnd: false}))
                  }
                  onOpen={() =>
                    setCreatedAtIsOpen((prevState) => ({...prevState, createdEnd: true}))
                  }
                />
              </InputFieldSet>
            </div>

            {cardType === CARD_TYPES.OPPORTUNITY.id ? (
              <div className='d-flex justify-content-between'>
                <InputFieldSet style={{marginRight: 5}} label={'Data da Prev. Fecham. Início'}>
                  <StyledDatetimePicker
                    ref={closeDateStartRef}
                    value={closeDateStartAt}
                    slotProps={{
                      field: {
                        clearable: true,
                      },
                    }}
                    onChange={(e: any) => {
                      setCloseDateStartAt(e)
                      setCloseDateAtIsOpen((prevState) => ({...prevState, dateStart: false}))
                    }}
                    open={closeDateAtIsOpen.dateStart}
                    onClose={() =>
                      setCloseDateAtIsOpen((prevState) => ({...prevState, dateStart: false}))
                    }
                    onOpen={() =>
                      setCloseDateAtIsOpen((prevState) => ({...prevState, dateStart: true}))
                    }
                  />
                </InputFieldSet>

                <InputFieldSet label={'Data da Prev. Fecham. Fim'}>
                  <StyledDatetimePicker
                    ref={closeDateEndRef}
                    value={closeDateEndAt}
                    slotProps={{
                      field: {
                        clearable: true,
                      },
                    }}
                    minDateTime={closeDateStartAt}
                    onChange={(e: any) => {
                      setCloseDateEndAt(e)
                      setCloseDateAtIsOpen((prevState) => ({...prevState, dateEnd: false}))
                    }}
                    open={closeDateAtIsOpen.dateEnd}
                    onClose={() =>
                      setCloseDateAtIsOpen((prevState) => ({...prevState, dateEnd: false}))
                    }
                    onOpen={() =>
                      setCloseDateAtIsOpen((prevState) => ({...prevState, dateEnd: true}))
                    }
                    closeOnSelect={false}
                  />
                </InputFieldSet>
              </div>
            ) : null}
            {/* begin::Actions */}
            <div className='d-flex justify-content-end mt-4'>
              <button
                type='button'
                disabled={isLoading}
                onClick={resetData}
                className='btn btn-sm btn-wl-custom-secondary-collor fw-bold me-2 px-6'
              >
                Limpar
              </button>
              <button
                disabled={isLoading}
                type='button'
                onClick={filterData}
                className='btn btn-sm btn-wl-custom-primary-collor fw-bold px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='filter'
              >
                Aplicar
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export {CardsListFilter}
