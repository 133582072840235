
import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'

import { ForgotPassword } from '../modules/auth/components/ForgotPassword'
import { RecoverPassword } from '../modules/auth/components/RecoverPassword'
import { ForcedLogOut } from '../modules/auth/components/ForcedLogOut'

import {ContactsFormEmbed} from '../../app/pages/contacts/embed/ContactsFormEmbed' //Precisa refatorar para pegar os formulários complementares
import {CompaniesFormEmbed} from '../../app/pages/companies/embed/CompaniesFormEmbed' //Precisa refatorar para pegar os formulários complementares

import {CardsFormEmbedPage} from '../pages/cards/embed/CardsFormEmbedPage'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
             {/* <Route path='/embed/contacts/*' element={<ContactsFormEmbed />} />
              <Route path='/embed/companies/*' element={<CompaniesFormEmbed />} />*/}
              <Route path='/embed/tickets/*' element={<CardsFormEmbedPage />} />
              <Route path='/embed/opportunities/*' element={<CardsFormEmbedPage />} />
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='login/*' element={<AuthPage />} />
              <Route path='forgot-password' element={<ForgotPassword />} />
              <Route path='recover-password/*' element={<RecoverPassword />} />
              <Route path='forced-logout/*' element={<ForcedLogOut />} />
              <Route path='*' element={<Navigate to='/login' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
