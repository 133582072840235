import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {Task, TasksQueryResponse} from './_models'
import {request} from '../../../services/crm-api/request'

const getTasks = async (query: string): Promise<TasksQueryResponse> => {
  return request(
    {
      method: 'GET',
      url: `/tasks?${query}&include_responsible=true&include_contact=true&include_company=true&include_card=true`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const exportTasks = async (query: string): Promise<any> => {
  return request(
    {
      method: 'GET',
      responseType: 'arraybuffer',
      url: `/tasks/export?${query}&include_responsible=true&include_company=true&include_card=true&include_contact=true`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<any>) => d)
}

const getTaskById = (id: ID): Promise<Task | undefined> => {
  return request(
    {
      method: 'GET',
      url: `/tasks/${id}?include_responsible=true&include_contact=true&include_company=true`,
    },
    {
      setAuth: true,
    }
  ).then((response: Response<Task>) => response.data)
}

const createTask = (dataCreate: Task): Promise<Task | undefined> => {
  return request(
    {
      method: 'post',
      url: `/tasks`,
      data: dataCreate,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Task>>) => response.data)
    .then((response: Response<Task>) => response.data)
}

const updateTask = (dataUpdate: Task): Promise<Task | undefined> => {
  return request(
    {
      method: 'patch',
      url: `/tasks/${dataUpdate.id}`,
      data: dataUpdate,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Task>>) => response.data)
    .then((response: Response<Task>) => response.data)
}

const completeTask = (id: any): Promise<Task | undefined> => {
  return request(
    {
      method: 'patch',
      url: `/tasks/${id}/completeTask`,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Task>>) => response.data)
    .then((response: Response<Task>) => response.data)
}

const removeTask = (id: any): Promise<Task | undefined> => {
  return request(
    {
      method: 'DELETE',
      url: `/tasks/${id}`,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Task>>) => response.data)
    .then((response: Response<Task>) => response.data)
}

const getUsers = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/users?status=1&${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getCompanies = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/companies?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getContacts = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/contacts?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getOpportunities = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/cards?${query}&type=1`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getTickets = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/cards?${query}&type=2`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getTasksTypes = async (query: string): Promise<any> => {
  return request(
    {
      method: 'GET',
      url: `/task-types?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

export {
  getTasks,
  getTaskById,
  createTask,
  updateTask,
  removeTask,
  completeTask,
  getUsers,
  getCompanies,
  getContacts,
  getTickets,
  getOpportunities,
  getTasksTypes,
  exportTasks,
}
