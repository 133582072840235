import {format} from 'date-fns'
import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {initialQueryState} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../modules/view/QueryRequestProvider'
import {useTaskFormModalDispatch} from '../../core/TaskFormModalContext'
import {ExportButton} from './ExportButton'
import {TasksListFilter} from './TasksListFilter'
import {TasksListSearchComponent} from './TasksListSearchComponent'

export interface TasksFilters {
  status: string | undefined
  type: string | undefined
  responsible: any | undefined
  dateStartAt: any | undefined
  dateEndAt: any | undefined
  search: string | undefined
}

const TasksListHeader = () => {
  const taskFormModalDispatch = useTaskFormModalDispatch()
  const {updateState} = useQueryRequest()
  const [filters, setFilters] = useState<TasksFilters>({
    status: '1',
    search: undefined,
    type: undefined,
    responsible: undefined,
    dateStartAt: undefined,
    dateEndAt: undefined,
  })

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const onUpdateState = () => {
    updateState({
      filter: {
        type: filters.type,
        status: filters.status,
        responsibleId: filters.responsible?.value ? filters.responsible.value : null,
        dateStartAt: filters.dateStartAt
          ? format(filters.dateStartAt, 'yyyy-MM-dd HH:mm:ss')
          : undefined,
        dateEndAt: filters.dateEndAt ? format(filters.dateEndAt, 'yyyy-MM-dd HH:mm:ss') : undefined,
      },
      ...initialQueryState,
    })
  }

  const onResetFilters = () => {
    setFilters({
      search: undefined,
      status: undefined,
      type: undefined,
      responsible: undefined,
      dateStartAt: undefined,
      dateEndAt: undefined,
    })
  }

  const openAddModal = () => {
    taskFormModalDispatch({
      type: 'open-add-modal',
    })
  }

  return (
    <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
      <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid'>
        <div
          id='kt_page_title'
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className='page-title d-flex flex-wrap me-3 flex-row justify-content-center'
        >
          <i className='fa-solid fa-filter fs-2 me-1'></i>
          <h3>
            <strong>Tarefas</strong>
          </h3>
        </div>

        <div className='d-flex align-items-center gap-2 gap-lg-3'>
          <TasksListSearchComponent filters={filters} setFilters={setFilters} />

          <TasksListFilter
            filters={filters}
            resetFilters={onResetFilters}
            setFilters={setFilters}
            onUpdateState={onUpdateState}
          />

          <a
            onClick={openAddModal}
            className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
            style={{marginLeft: '3rem', position: 'relative'}}
          >
            Adicionar
          </a>

          <ExportButton filters={filters} />
        </div>
      </div>
    </div>
  )
}

export {TasksListHeader}
