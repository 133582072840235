import {format} from 'date-fns'
import {useState} from 'react'
import Swal from 'sweetalert2'
import {exportTasks} from '../../core/_requests'
import {TasksFilters} from './TasksListHeader'

export function ExportButton({filters}: {filters: TasksFilters}) {
  const [loadingExport, setLoadingExport] = useState(false)

  async function confirm() {
    const res = await Swal.fire({
      title: 'Exportar',
      text: 'Deseja realmente exportar todas as informações? Esta ação pode levar algum tempo.',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
    })

    if (!res.isConfirmed) return

    await exportData()
  }

  async function exportData() {
    setLoadingExport(true)

    try {
      let params = []

      if (filters.dateStartAt && filters.dateEndAt) {
        const dStart = format(filters.dateStartAt, 'yyyy-MM-dd 00:00:00')
        const dEnd = format(filters.dateEndAt, 'yyyy-MM-dd 23:59:59')
        params.push(`createdAtStart=${encodeURIComponent(dStart)}`)
        params.push(`createdAtEnd=${encodeURIComponent(dEnd)}`)
      }

      if (filters.responsible?.value) {
        params.push(`responsibleId=${encodeURIComponent(filters.responsible.value)}`)
      }

      if (filters.status) {
        params.push(`status=${encodeURIComponent(filters.status)}`)
      }

      if (filters.type) {
        params.push(`type=${encodeURIComponent(filters.type)}`)
      }

      if (filters.search) {
        params.push(`search=${encodeURIComponent(filters.search)}`)
      }

      let query = params.length > 0 ? `${params.join('&')}` : ''

      let response = await exportTasks(query)

      let headers = response.headers
      let blob = new Blob([response.data], {
        type: headers['content-type'],
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Tarefas'
      link.click()
      setLoadingExport(false)
      Swal.fire({
        title: 'Exportação feita com sucesso',
        icon: 'success',
      })
    } catch (e: any) {
      console.error(JSON.stringify(e))
      setLoadingExport(false)
      let errorDetected = ''

      if (e.response?.data?.key && e.response?.data?.message) {
        errorDetected = e.response?.data?.message
      }
      if (e.response?.data?.message) {
        errorDetected = e.response?.data?.message
      }
      if (errorDetected.length) {
        Swal.fire('Opss', 'Falha ao realizar a exportação. ' + errorDetected, 'error')
      } else {
        Swal.fire('Opss', 'Falha ao realizar a exportação.', 'error')
      }
    }
  }

  return (
    <a
      href='#'
      onClick={async (e) => {
        if (loadingExport) {
          return
        } else {
          await confirm()
        }
      }}
      className={`btn btn-sm fw-bold ${
        loadingExport ? 'btn-secondary' : 'btn-wl-custom-primary-collor'
      }`}
      title='Exportar'
      style={{
        pointerEvents: loadingExport ? 'none' : 'auto',
        opacity: loadingExport ? 0.5 : 1,
      }}
    >
      <i className='fa-solid fa-file-excel p-0'></i>
    </a>
  )
}
