/* eslint-disable jsx-a11y/anchor-is-valid */
import {format, isAfter} from 'date-fns'
import debounce from 'debounce-promise'
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {Dropdown, Spinner} from 'react-bootstrap'
import Select, {components} from 'react-select'
import SelectAsync from 'react-select/async'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import {useDebounce} from '../../../_metronic/helpers'
import {FUNNEL_TYPES} from '../../../_metronic/helpers/consts'
import {InputFieldSet} from '../../../_metronic/partials/inputs/InputFieldSet'
import {useAuth} from '../../modules/auth'
import StyledDatePicker from '../../modules/view/StyledDatePicker'
import SelectInputCustomStyle from '../../modules/view/styles/SelectInputCustomStyle'
import {useCardFormModalDispatch} from '../cards/core/CardFormModalContext'
import {CARD_STATUS, CARD_TYPES} from '../cards/core/_models'
import {getCompanies} from '../cards/core/_requests'
import {getLabels} from '../settings/labels/core/_requests'
import CustomDropdownOptions from './AuxComponents/CustomDropdownOptions'
import {useFunnel, useFunnelDispatch} from './core/FunnelContext'
import {
  getCardsFunnel,
  getExportFunnel,
  getGlobalSearch,
  getPossibleResponsiblesFilter,
} from './core/_requests'
type Form = {
  status: any
  responsible: any | undefined
  created_at_start: any | undefined
  created_at_end: any | undefined
  update_at_start: any | undefined
  update_at_end: any | undefined
  close_date_start: any | undefined
  close_date_end: any | undefined
  companyId: any | undefined
  labelIds: any | undefined
}

const initialFilterFormState = {
  status: {label: 'Aberto', value: CARD_STATUS.OPEN.id},
  responsible: null,
  created_at_start: null,
  created_at_end: null,
  update_at_start: null,
  update_at_end: null,
  close_date_start: null,
  close_date_end: null,
  companyId: null,
  labelIds: null,
}

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none;
  }
`
const UNITS_TIMEZONE = 'America/Sao_Paulo'

const ClearIndicator = (props: any) => {
  const {children = <components.ClearIndicator {...props} />, clearValue, innerProps} = props

  return (
    <div
      {...innerProps}
      onMouseDown={(e) => {
        e.stopPropagation() // Impede que o evento clique se propague
        clearValue() // Limpa o valor
      }}
    >
      {children}
    </div>
  )
}

const FunnelPageToolbar: FC<any> = ({}: any) => {
  const [controlDropdownSearch, setControlDropdownSearch] = useState<any>({
    show: false,
    data: null,
    onBlurInput: false,
  })
  const cardFormModalDispatch = useCardFormModalDispatch()
  const {currentUser} = useAuth()
  const funnel = useFunnel()
  const funnelDispatch = useFunnelDispatch()

  const [showDropdown, setShowDropdown] = useState(false)
  const [form, setForm] = useState<Form>(initialFilterFormState)
  const dropdownRef: any = useRef(null)
  const closeDateStartRef: any = useRef(null)
  const closeDateEndRef: any = useRef(null)
  const createdStartRef: any = useRef(null)
  const createdEndRef: any = useRef(null)
  const updateStartRef: any = useRef(null)
  const updateEndRef: any = useRef(null)

  const [calendar, setCalendar] = useState({
    createdStart: false,
    createdEnd: false,
    updateStart: false,
    updateEnd: false,
    closeDateStart: false,
    closeDateEnd: false,
  })

  const loadCardsFunnel = async () => {
    if (!funnel.currentFunnel?.id) return

    let filter = form

    funnelDispatch({
      type: 'start-loading-funnel-steps',
    })

    let query = ''

    if (filter.status?.value) query += `&status=${filter.status?.value}`

    if (filter.responsible?.value) query += `&responsibleId=${filter.responsible?.value}`

    if (filter.created_at_start) {
      const dStart = format(filter.created_at_start, 'yyyy-MM-dd 00:00:00')
      query += `&createdAtStart=${dStart}`
    }

    if (filter.created_at_end) {
      const dEnd = format(filter.created_at_end, 'yyyy-MM-dd 23:59:59')
      query += `&createdAtEnd=${dEnd}`
    }

    if (filter.update_at_start) {
      const dStart = format(filter.update_at_start, 'yyyy-MM-dd 00:00:00')
      query += `&updatedAtStart=${dStart}`
    }

    if (filter.update_at_end) {
      const dEnd = format(filter.update_at_end, 'yyyy-MM-dd 23:59:59')
      query += `&updatedAtEnd=${dEnd}`
    }

    if (filter.close_date_start) {
      const dStart = format(filter.close_date_start, 'yyyy-MM-dd 00:00:00')
      query += `&closeDateStart=${dStart}`
    }

    if (filter.close_date_end) {
      const dEnd = format(filter.close_date_end, 'yyyy-MM-dd 23:59:59')
      query += `&closeDateEnd=${dEnd}`
    }

    if (filter?.companyId?.value) query += `&companyId=${filter.companyId?.value}`

    if (filter?.labelIds && filter?.labelIds.length > 0) {
      let labelIds = ''
      for (let i = 0; i < filter.labelIds.length; i++) {
        if (i > 0) labelIds += ','
        labelIds += filter.labelIds[i].value
      }
      query += `&labelIds=${labelIds}`
    }

    let cardsStep = await getCardsFunnel(funnel.currentFunnel.id, query)

    funnelDispatch({
      type: 'set-current-steps-cards',
      data: cardsStep,
    })
  }

  const statusOptions = useMemo(() => {
    let options: any[] = []

    options.push({value: '', label: 'Todos'})

    if (!funnel?.currentFunnel?.id) return options

    let funnelType: any = funnel?.currentFunnel?.type

    if (!funnelType) return options

    Object.values(CARD_STATUS).forEach((status) => {
      if (status.cardType.includes(funnelType)) {
        options.push({
          value: status.id,
          label: status.label,
        })
      }
    })

    return options
  }, [funnel.currentFunnel])

  useEffect(() => {
    if (!funnel?.currentFunnel?.id) return

    const startLoad = async () => {
      try {
        await loadCardsFunnel()
      } catch (e) {
        console.log(e)
      }
    }

    startLoad()
  }, [funnel?.currentFunnel])

  //atualizar filtros no context dinamicamente...
  // useEffect(() => {
  //   let newFilters = {...form}
  //   funnelDispatch({
  //     type: 'set-filters-data',
  //     data: newFilters,
  //   })
  // }, [form])

  const loadTitle = () => {
    if (!funnel?.currentFunnel) return ''
    let title = ''
    switch (funnel.currentFunnel.type) {
      case CARD_TYPES.OPPORTUNITY.id:
        title = 'Oportunidade'
        break
      case CARD_TYPES.TICKET.id:
        title = 'Tícket'
        break
    }
    return title
  }
  const responsiblesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps: any = await getPossibleResponsiblesFilter(
        'search=' + encodeURIComponent(inputValue) + '&only_active=true'
      )
      if (resps) {
        for (let i = 0; i < resps?.length; i++) {
          list.push({label: resps[i]?.name, value: resps[i]?.id})
        }
      }
      return resolve(list)
    })
  const debounceResponsiblesOptions = debounce(responsiblesOptions, 500)

  const companiesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let companies = await getCompanies(
        'page=1&perPage=20&search=' + encodeURIComponent(inputValue)
      )
      if (companies && companies.data != undefined) {
        for (let i = 0; i < companies?.data?.length; i++) {
          list.push({label: companies.data[i].name, value: companies.data[i].id})
        }
      }
      resolve(list)
    })

  const debounceCompaniesOptions = debounce(companiesOptions, 500)

  const labelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let labels = await getLabels('page=1&perPage=100&search=' + encodeURIComponent(inputValue))
      if (labels && labels.data != undefined) {
        for (let i = 0; i < labels?.data?.length; i++) {
          list.push({label: labels.data[i].name, value: labels.data[i].id})
        }
      }
      resolve(list)
    })

  const debounceLabelsOptions = debounce(labelsOptions, 500)

  const [globalSearchText, setGlobalSearchText] = useState('')
  const [controlLoadingSearch, setControlLoadingSearch] = useState({
    show: false,
  })
  const [loadingExport, setLoadingExport] = useState(false)
  const debouncedSearch = useDebounce(globalSearchText, 500)

  const handleGlobalSearch = async () => {
    if (globalSearchText.length < 3) return

    setControlLoadingSearch({
      show: true,
    })
    try {
      // await new Promise((resolve) => setTimeout(resolve, 1000))

      let response: any = await getGlobalSearch(encodeURIComponent(globalSearchText))

      let formatedData: any = []
      formatedData = [...formatedData]?.concat(
        response?.contacts?.map((item: any) => {
          if (item) {
            let itemData = {...item}
            itemData.entity_type = 'contact'
            return itemData
          }
        })
      )

      formatedData = [...formatedData]?.concat(
        response?.cards?.map((item: any) => {
          if (item) {
            let itemData = {...item}
            itemData.entity_type = 'card'
            return itemData
          }
        })
      )

      formatedData = [...formatedData]?.concat(
        response?.companies?.map((item: any) => {
          if (item) {
            let itemData = {...item}
            itemData.entity_type = 'company'
            return itemData
          }
        })
      )

      let filteredData: any = []
      for (let i = 0; i < formatedData?.length; i++) {
        let use = formatedData[i]
        if (
          !filteredData?.find((e: any) => e?.id == use?.id && e?.entity_type == use?.entity_type)
        ) {
          filteredData.push(use)
        }
      }

      setControlDropdownSearch({
        show: true,
        data: filteredData,
      })
    } catch (e) {
      console.log(e)
    }
    setControlLoadingSearch({
      show: false,
    })
  }

  const handleInputChange = (event: any) => {
    const searchTerm = event.target.value
    setGlobalSearchText(searchTerm)
  }
  useEffect(() => {
    handleGlobalSearch()
  }, [debouncedSearch])

  const onSubmit = () => {
    if (form?.created_at_start && form?.created_at_end) {
      if (isAfter(form.created_at_start, form.created_at_end)) {
        return Swal.fire({
          title: 'Atenção',
          text: 'Data de Criação Início não pode ser maior do que Data de Criação Fim',
          icon: 'warning',
          confirmButtonText: 'OK',
        })
      }
    }
    if (form?.update_at_start && form?.update_at_end) {
      if (isAfter(form.update_at_start, form.update_at_end)) {
        return Swal.fire({
          title: 'Atenção',
          text: 'Data de Alteração Início não pode ser maior do que Data de Alteração Fim',
          icon: 'warning',
          confirmButtonText: 'OK',
        })
      }
    }

    if (form.created_at_start) {
      form.created_at_start = new Date(form.created_at_start.toISOString())
      //form.created_at_start = zonedTimeToUtc(form.created_at_start,UNITS_TIMEZONE);
    }
    if (form.created_at_end) {
      form.created_at_end = new Date(form.created_at_end.toISOString())
      //form.created_at_end = zonedTimeToUtc(form.created_at_end,UNITS_TIMEZONE);
    }
    if (form.update_at_start) {
      form.update_at_start = new Date(form.update_at_start.toISOString())
      //form.update_at_start = zonedTimeToUtc(form.update_at_start,UNITS_TIMEZONE);
    }
    if (form.update_at_end) {
      form.update_at_end = new Date(form.update_at_end.toISOString())
      //form.update_at_end = zonedTimeToUtc(form.update_at_end,UNITS_TIMEZONE);
    }

    if (form.close_date_start) {
      form.close_date_start = new Date(form.close_date_start.toISOString())
      //form.close_date_start = zonedTimeToUtc(form.close_date_start,UNITS_TIMEZONE);
    }

    if (form.close_date_end) {
      form.close_date_end = new Date(form.close_date_end.toISOString())
      //form.close_date_end = zonedTimeToUtc(form.close_date_end,UNITS_TIMEZONE);
    }

    //console.log("formfilter",form)

    setShowDropdown(false)

    loadCardsFunnel()

    funnelDispatch({
      type: 'set-filters-data',
      data: form,
    })
  }

  const loadingTotalValueFunnel = () => {
    let total = 0
    try {
      funnel?.currentStepsCards?.map(
        (item: any, index: number) => (total += item?.statistics?.sumTotalValue)
      )
    } catch (e) {
      console.log('calc value total funnel error', e)
    }

    if (total > 0) return total

    return 0
  }

  const loadingTotalCardsAndSteps = () => {
    let totalSteps: any = 0
    let totalCards: any = 0

    try {
      if (funnel?.currentStepsCards && funnel?.currentStepsCards?.length > 0) {
        totalSteps = funnel?.currentStepsCards?.length

        for (let i = 0; i < funnel?.currentStepsCards?.length; i++) {
          let use = funnel?.currentStepsCards[i]
          if (use?.cards?.length > 0) {
            totalCards += use?.cards?.length
          }
        }
      }
    } catch (e) {
      console.log(e)
    }

    return (
      <small style={{fontWeight: 'bold', color: '#bcbcbc', fontSize: '12px'}}>
        {' '}
        - {totalSteps} etapas - {totalCards} cards
      </small>
    )
  }

  useEffect(() => {
    let timeoutId: any = null
    function handleClickOutside(event: any) {
      if (
        dropdownRef?.current &&
        !dropdownRef.current?.contains(event?.target) &&
        !createdStartRef.current?.contains(event?.target) &&
        !createdEndRef.current?.contains(event?.target) &&
        !updateEndRef.current?.contains(event?.target) &&
        !updateStartRef.current?.contains(event?.target)
      ) {
        //if para ve se onBlur foi fora do dropdown e se todos os calendarios estiverem fechados
        if (!Object.values(calendar).some((status) => status)) {
          timeoutId = setTimeout(() => {
            //delay pra nao dar bug visual
            setShowDropdown(false)
          }, 150)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [calendar])

  const confirmExport = () => {
    Swal.fire({
      title: 'Exportar Funil',
      text: 'Você tem certeza de que deseja exportar este funil? A exportação levará em conta os filtros selecionados como referência para realizar essa ação.',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          setLoadingExport(true)
          let filter = form

          let queryParams = []

          if (filter.status?.value)
            queryParams.push(`status=${encodeURIComponent(filter.status.value)}`)

          if (filter.responsible?.value)
            queryParams.push(`responsibleId=${encodeURIComponent(filter.responsible.value)}`)

          if (filter.created_at_start && filter.created_at_end) {
            const dStart = format(filter.created_at_start, 'yyyy-MM-dd 00:00:00')
            const dEnd = format(filter.created_at_end, 'yyyy-MM-dd 23:59:59')
            queryParams.push(`createdAtStart=${encodeURIComponent(dStart)}`)
            queryParams.push(`createdAtEnd=${encodeURIComponent(dEnd)}`)
          }

          if (filter.update_at_start && filter.update_at_end) {
            const dStart = format(filter.update_at_start, 'yyyy-MM-dd 00:00:00')
            const dEnd = format(filter.update_at_end, 'yyyy-MM-dd 23:59:59')
            queryParams.push(`updatedAtStart=${encodeURIComponent(dStart)}`)
            queryParams.push(`updatedAtEnd=${encodeURIComponent(dEnd)}`)
          }

          if (filter?.companyId?.value)
            queryParams.push(`companyId=${encodeURIComponent(filter.companyId.value)}`)

          if (filter?.labelIds && filter?.labelIds.length > 0) {
            let labelIds = ''
            for (let i = 0; i < filter.labelIds.length; i++) {
              if (i > 0) labelIds += ','
              labelIds += filter.labelIds[i].value
            }
            queryParams.push(`labelIds=${labelIds}`)
          }

          let query = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''

          let response = await getExportFunnel(funnel.currentFunnel.id, query)
          let headers = response.headers

          let blob = new Blob([response.data], {
            type: headers['content-type'],
          })
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'Funil'
          link.click()
          setLoadingExport(false)
          Swal.fire({
            title: 'Exportação feita com sucesso',
            icon: 'success',
          })
        } catch (e: any) {
          setLoadingExport(false)
          let errorDetected = ''

          if (e.response?.data?.key && e.response?.data?.message) {
            errorDetected = e.response?.data?.message
          }
          if (e.response?.data?.message) {
            errorDetected = e.response?.data?.message
          }
          if (errorDetected.length) {
            Swal.fire('Opss', 'Falha ao Exportar o funil. ' + errorDetected, 'error')
          } else {
            Swal.fire(
              'Opss',
              'Falha ao Exportar o funil. Verifique o se o intervalo da data de criação é de no máximo 6 meses.',
              'error'
            )
          }
        }
      } else if (result.isDenied) {
      }
    })
  }

  return (
    <div id='kt_app_toolbar' className='py-3 py-lg-6'>
      <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid'>
        <div
          id='kt_page_title'
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className='page-title d-flex flex-wrap me-3 flex-row justify-content-center'
        >
          <i className='fa-solid fa-filter fs-2 me-1'></i>
          <h3>
            <strong>
              Funil{' '}
              {FUNNEL_TYPES?.SALES == funnel?.currentFunnel?.type ? (
                <span className='text-wl-custom-title-collor'>
                  (
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(loadingTotalValueFunnel())}
                  )
                </span>
              ) : null}
              {loadingTotalCardsAndSteps()}
            </strong>
          </h3>
        </div>
        <div className='d-flex align-items-center gap-2 gap-lg-3'>
          <InputFieldSet label={'Funil'}>
            <select
              name='funnel'
              data-control='select2'
              data-hide-search='true'
              className='form-control form-select form-select-sm w-125px fieldset-input'
              onChange={(e) => {
                funnelDispatch({
                  type: 'set-current-funnel-id',
                  data: e.target.value,
                })
                setForm({
                  ...initialFilterFormState,
                })
              }}
              defaultValue={funnel?.currentFunnel?.id}
            >
              {funnel?.funnels.map((el: any) => (
                <option key={el.id?.toString()} value={el.id?.toString()}>
                  {el.name}
                </option>
              ))}
            </select>
          </InputFieldSet>
          <InputFieldSet label={'Pesquisar'}>
            <div
              className='d-flex align-items-start position-relative'
              onBlur={() => {
                let data = {...controlDropdownSearch}
                data.onBlurInput = true
                setControlDropdownSearch(data)
                // setControlDropdownSearch({
                //   show: false,
                //   data: null,
                // })
              }}
              onClick={() => {
                let data = {...controlDropdownSearch}
                data.show = true
                setControlDropdownSearch(data)
                handleGlobalSearch()
              }}
            >
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control w-250px fieldset-input'
                placeholder='Pesquisar...'
                onChange={handleInputChange}
                style={{paddingRight: '3.5rem'}}
              />
              {controlLoadingSearch.show ? (
                <Spinner
                  variant='wl-custom-title-collor'
                  size='sm'
                  style={{position: 'relative', left: '-12px'}}
                />
              ) : (
                <i
                  className='fas fa-search'
                  style={{
                    position: 'relative',
                    left: '-12px',
                    fontSize: '17px',
                    cursor: 'pointer',
                    color: '#93a4ee',
                  }}
                ></i>
              )}
            </div>
            <CustomDropdownOptions
              show={controlDropdownSearch.show}
              data={controlDropdownSearch.data}
              leaveInput={controlDropdownSearch.onBlurInput}
              setControlDropdownSearch={setControlDropdownSearch}
            />
          </InputFieldSet>
          <div className='m-0'>
            <Dropdown ref={dropdownRef} id='dropdown-true' show={showDropdown}>
              <StyledDropdownToggle
                variant='wl-custom-primary-collor-inverse'
                id='dropdown-basic'
                className='btn btn-sm me-3'
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <i className='fa-solid fa-filter fs-2'></i>
                Filtros
              </StyledDropdownToggle>

              <Dropdown.Menu className='menu menu-sub menu-sub-dropdown w-300px w-md-450px'>
                <Dropdown.Header className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
                </Dropdown.Header>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  <div className='mb-7'>
                    <InputFieldSet label={'Status'}>
                      <Select
                        name='status'
                        options={statusOptions}
                        onChange={(e) => {
                          setForm((prevState: any) => ({
                            ...prevState,
                            ['status']: e,
                          }))
                        }}
                        className='fieldset-input'
                        value={form.status}
                        styles={SelectInputCustomStyle}
                      />
                    </InputFieldSet>
                  </div>
                  <div className='mb-7'>
                    <InputFieldSet label={'Responsável'}>
                      <SelectAsync
                        placeholder={''}
                        name='responsible'
                        loadOptions={debounceResponsiblesOptions}
                        components={{ClearIndicator}} //corrigir bug de fechar dropdown apos clicar no X do select
                        isClearable
                        value={form.responsible}
                        onChange={(e) => {
                          setForm((prevState: any) => ({
                            ...prevState,
                            ['responsible']: e,
                          }))
                        }}
                        noOptionsMessage={() => 'Sem registros...Digite para buscar'}
                        className='fieldset-input'
                        styles={SelectInputCustomStyle}
                      />
                    </InputFieldSet>
                  </div>

                  <div className='mb-7'>
                    <InputFieldSet label={'Empresa'}>
                      <SelectAsync
                        menuPortalTarget={document.body}
                        placeholder={''}
                        components={{ClearIndicator}} //corrigir bug de fechar dropdown apos clicar no X do select
                        isClearable
                        name='companyId'
                        loadOptions={debounceCompaniesOptions}
                        value={form.companyId}
                        noOptionsMessage={() => 'Sem registros...Digite para buscar'}
                        onChange={(e: any, actionMeta: any) => {
                          setForm((prevState: any) => ({
                            ...prevState,
                            ['companyId']: e,
                          }))
                        }}
                        className='fieldset-input'
                        styles={SelectInputCustomStyle}
                      />
                    </InputFieldSet>
                  </div>

                  <div className='mb-7'>
                    <InputFieldSet label={'Etiquetas'}>
                      <SelectAsync
                        menuPortalTarget={document.body}
                        placeholder={''}
                        components={{ClearIndicator}} //corrigir bug de fechar dropdown apos clicar no X do select
                        isClearable
                        isMulti
                        defaultOptions
                        name='labelIds'
                        loadOptions={debounceLabelsOptions}
                        value={form.labelIds}
                        noOptionsMessage={() => 'Sem registros...Digite para buscar'}
                        onChange={(e: any, actionMeta: any) => {
                          setForm((prevState: any) => ({
                            ...prevState,
                            ['labelIds']: e,
                          }))
                        }}
                        className='fieldset-input'
                        styles={SelectInputCustomStyle}
                      />
                    </InputFieldSet>
                  </div>

                  <div className='d-flex justify-content-between'>
                    <Dropdown.Item
                      className='px-0'
                      style={{width: '48%', backgroundColor: 'transparent'}}
                    >
                      <InputFieldSet label={'Data de Criação Início'}>
                        <StyledDatePicker
                          ref={createdStartRef}
                          value={form.created_at_start}
                          onChange={(date: any) => {
                            setCalendar((prevState) => ({...prevState, createdStart: false}))
                            setForm((prev: any) => ({...prev, ['created_at_start']: date}))
                          }}
                          open={calendar.createdStart}
                          onOpen={() => {
                            setCalendar((prevState) => ({...prevState, createdStart: true}))
                          }}
                          onClose={() =>
                            setCalendar((prevState) => ({...prevState, createdStart: false}))
                          }
                        />
                      </InputFieldSet>
                    </Dropdown.Item>

                    <Dropdown.Item
                      className='px-0'
                      style={{width: '48%', backgroundColor: 'transparent'}}
                    >
                      <InputFieldSet label={'Data de Criação Fim'}>
                        <StyledDatePicker
                          ref={createdEndRef}
                          value={form.created_at_end}
                          onChange={(date: any) => {
                            setCalendar((prevState) => ({...prevState, createdEnd: false}))
                            setForm((prev: any) => ({...prev, ['created_at_end']: date}))
                          }}
                          open={calendar.createdEnd}
                          onOpen={() => {
                            setCalendar((prevState) => ({...prevState, createdEnd: true}))
                          }}
                          onClose={() =>
                            setCalendar((prevState) => ({...prevState, createdEnd: false}))
                          }
                        />
                      </InputFieldSet>
                    </Dropdown.Item>
                  </div>

                  <div className='d-flex justify-content-between mt-2'>
                    <Dropdown.Item
                      className='px-0'
                      style={{width: '48%', backgroundColor: 'transparent'}}
                    >
                      <InputFieldSet label={'Data de Alteração Início'}>
                        <StyledDatePicker
                          ref={updateStartRef}
                          value={form.update_at_start}
                          onChange={(date: any) => {
                            setCalendar((prevState) => ({...prevState, updateStart: false}))
                            setForm((prev: any) => ({...prev, ['update_at_start']: date}))
                          }}
                          open={calendar.updateStart}
                          onOpen={() => {
                            setCalendar((prevState) => ({...prevState, updateStart: true}))
                          }}
                          onClose={() =>
                            setCalendar((prevState) => ({...prevState, updateStart: false}))
                          }
                        />
                      </InputFieldSet>
                    </Dropdown.Item>

                    <Dropdown.Item
                      className='px-0'
                      style={{width: '48%', backgroundColor: 'transparent'}}
                    >
                      <InputFieldSet label={'Data de Alteração Fim'}>
                        <StyledDatePicker
                          ref={updateEndRef}
                          value={form.update_at_end}
                          onChange={(date: any) => {
                            setCalendar((prevState) => ({...prevState, updateEnd: false}))
                            setForm((prev: any) => ({...prev, ['update_at_end']: date}))
                          }}
                          open={calendar.updateEnd}
                          onOpen={() => {
                            setCalendar((prevState) => ({...prevState, updateEnd: true}))
                          }}
                          onClose={() =>
                            setCalendar((prevState) => ({...prevState, updateEnd: false}))
                          }
                        />
                      </InputFieldSet>
                    </Dropdown.Item>
                  </div>

                  {funnel?.currentFunnel?.type === CARD_TYPES.OPPORTUNITY.id ? (
                    <div className='d-flex justify-content-between'>
                      <Dropdown.Item
                        className='px-0'
                        style={{width: '48%', backgroundColor: 'transparent'}}
                      >
                        <InputFieldSet label={'Data da Prev. Fecham. Início'}>
                          <StyledDatePicker
                            ref={closeDateStartRef}
                            value={form.close_date_start}
                            onChange={(date: any) => {
                              setCalendar((prevState) => ({...prevState, closeDateStart: false}))
                              setForm((prev: any) => ({...prev, ['close_date_start']: date}))
                            }}
                            open={calendar.closeDateStart}
                            onOpen={() => {
                              setCalendar((prevState) => ({...prevState, closeDateStart: true}))
                            }}
                            onClose={() =>
                              setCalendar((prevState) => ({...prevState, closeDateStart: false}))
                            }
                          />
                        </InputFieldSet>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className='px-0'
                        style={{width: '48%', backgroundColor: 'transparent'}}
                      >
                        <InputFieldSet label={'Data da Prev. Fecham. Fim'}>
                          <StyledDatePicker
                            ref={closeDateEndRef}
                            value={form.close_date_end}
                            onChange={(date: any) => {
                              setCalendar((prevState) => ({...prevState, closeDateEnd: false}))
                              setForm((prev: any) => ({...prev, ['close_date_end']: date}))
                            }}
                            open={calendar.closeDateEnd}
                            onOpen={() => {
                              setCalendar((prevState) => ({...prevState, closeDateEnd: true}))
                            }}
                            onClose={() =>
                              setCalendar((prevState) => ({...prevState, closeDateEnd: false}))
                            }
                          />
                        </InputFieldSet>
                      </Dropdown.Item>
                    </div>
                  ) : null}
                </div>

                <div className='d-flex justify-content-end p-3 mt-2'>
                  <button
                    type='button'
                    onClick={() => {
                      setForm({
                        ...initialFilterFormState,
                      })
                    }}
                    className='btn btn-sm btn-wl-custom-secondary-collor fw-bold me-2 px-6'
                  >
                    Limpar
                  </button>
                  <button
                    type='button'
                    onClick={onSubmit}
                    className='btn btn-sm btn-wl-custom-primary-collor fw-bold px-6'
                  >
                    Aplicar
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <a
            href='#'
            onClick={() => {
              cardFormModalDispatch({
                type: 'open-add-modal',
                data: {
                  type: funnel?.currentFunnel?.type,
                  addData: {
                    funnelId: {
                      label: funnel?.currentFunnel?.name,
                      value: funnel?.currentFunnel?.id,
                    },
                  },
                  funnelId: funnel?.currentFunnel?.id,
                },
              })
            }}
            className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
          >
            Criar {loadTitle()}
          </a>

          {(currentUser?.role?.key === 'admin' || currentUser?.role?.key === 'supervisor') && (
            <a
              href='#'
              onClick={(e) => {
                if (loadingExport) {
                  return
                } else {
                  confirmExport()
                }
              }}
              className={`btn btn-sm fw-bold ${
                loadingExport ? 'btn-secondary' : 'btn-wl-custom-primary-collor'
              }`}
              style={{
                pointerEvents: loadingExport ? 'none' : 'auto',
                opacity: loadingExport ? 0.5 : 1,
              }}
            >
              <i className='fa-solid fa-file-excel p-0'></i>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default FunnelPageToolbar
