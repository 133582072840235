export function ForcedLogOut() {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  if (window && window.parent && window.parent.postMessage) {
    window.parent.postMessage(`{"origin": "trictionCRM", "event": "forcedLogout"}`, '*')
  }

  setTimeout(() => ( window.location.href = "/login"), 10000);

  return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>
    Sua sessão foi desconectada.<br />
    Realize o login novamente.<br /><br />
    
    <a href="#" onClick={() => {window.location.href = "/login"}}>Você será direcionado para a tela de login em 10 segundos.</a>
  </div>
}
